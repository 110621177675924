import React, { useEffect, useRef } from "react";
import { aboutContent } from "../../constants/content";
import AboutImg from "../../assets/about/about-img.png";
import IroningCost from "../../assets/cost/ironing-cost.png";
import { useLocation } from "react-router-dom";
function About() {
  const ironingCostRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.shouldScroll) {
      ironingCostRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.state]);

  return (
    <div className="w-screen">
      <div className="py-5 border-t xl:px-10 xl:py-10 border-t-white bg-primary flex flex-col xl:flex-row px-2 justify-center">
        <div className="flex flex-col">
          <p className="text-2xl font-extrabold text-white pl-2">ABOUT US</p>
          <p className="text-xl xl:w-60 font-bold mt-3 text-tertiary pl-2">
            We Make Your Clothes As Good As New
          </p>
        </div>
        <br />
        <br />
        <p className="ml-2 text-white w-[90%] xl:w-[50%] xl:ml-20 md:text-center md:self-center text-[17px] ">
          {aboutContent.aboutMe}
        </p>
      </div>
      <img src={AboutImg} alt="about-img" className="object-cover w-screen" />
      <br />
      <div className="mt-5 grid grid-cols-2 xl:flex xl:flex-row xl:justify-between px-10 transition-opacity duration-500">
        {aboutContent.AchievementsData.map((item) => {
          return (
            <Achievements key={item.id} value={item.value} title={item.title} />
          );
        })}
      </div>
      <br/>
      <div className="w-screen h-[1px] bg-quaternary"></div>
      <br/>
    </div>
  );
}

export default About;

function Achievements({ value, title }) {
  return (
    <div className="flex flex-col items-center my-5">
      <p className="font-extrabold text-quaternary text-2xl md:text-4xl">
        {value}
      </p>
      <p className=" mt-2 font-normal w-[100px] md:w-[200px] text-center md:text-2xl text-quaternary text-xl">
        {title}
      </p>
    </div>
  );
}
