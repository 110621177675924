import React from "react";
import { HomeContent } from "../../constants/content";
import CustomButton from "../UI/CustomButton";
import { BsWhatsapp } from "react-icons/bs";
import "../../App.css"
function AboutUs() {
  return (
    <>

      <div className="bg-[url(https://i.ibb.co/pnKqWks/home-bg.jpg)] bg-homeBg bg-center bg-cover w-screen h-fit flex-col flex items-center min-h-40 py-8">
        <p
          className="text-wrap md:text-xl xl:text-2xl font-extrabold mt-10 text-center tracking-wide w-[80%] md:w-[60%]"
        // style={{
        //   textShadow:
        //     "0.5px 0.5px 0 #999, -0.5px -0.5px 0 #999, 0.5px -0.5px 0 #999, -0.5px 0.5px 0 #999",
        // }}
        >
          <span
            className="md:text-xl xl:text-2xl text-[22px] font-extrabold"
            style={{
              color: "white",
              textShadow:
                "0.7px 0.7px 0 #000, -0.5px 0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000",
            }}
          >
            {" "}
            Made in India
          </span>{" "}
          {/* White part */}
          <span
            className="md:text-xl xl:text-2xl text-[22px] font-extrabold"
            style={{ color: "#138808" }}
          >
            🇮🇳 Vocal For Local
          </span>{" "}
          {/* Green part */}
        </p>

        <p
          className="text-white text-wrap text-[30px] md:text-xl xl:text-2xl font-extrabold mt-10 text-center tracking-wide w-[80%] md:w-[60%]"
          style={{
            textShadow:
              "0.5px 0.5px 0 #000, -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000",
          }}
        >
          {HomeContent.wlcMsg}
        </p>
        <p
          className="text-white text-wrap text-[30px] md:text-xl xl:text-2xl font-extrabold mt-10 text-center tracking-wide w-[80%] md:w-[60%]"
          style={{
            textShadow:
              "0.5px 0.5px 0 #fff, -0.5px -0.5px 0 #fff, 0.5px -0.5px 0 #fff, -0.5px 0.5px 0 #fff",
          }}s
        >
          <span className="text-[#046A38] text-3xl">{HomeContent.subHeadAboutMsg.wash}</span> -
          <span className="text-[#046A38] text-3xl">{HomeContent.subHeadAboutMsg.ironing}</span> - 
          <span className="text-[#046A38] text-3xl">{HomeContent.subHeadAboutMsg.dryClean}</span>
        </p>
        <p
          className="text-white md:text-xl xl:text-2xl mb-10 text-[18px] font-semibold text-center mt-10 w-[90%] xl:w-[40%]"
          style={{
            textShadow:
              "0.5px 0.5px 0 #000, -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000",
          }}
        >
          {HomeContent.aboutMsg}
        </p>
        <CustomButton onPress={() => (window.location.href = "/about")} label={"Learn More"} />
      </div>
      <div
        onClick={() =>
          (window.location.href = `https://wa.me/${HomeContent.pureWhatsAppNumber}`)
        }
        className="flex flex-row items-center justify-center py-9 cursor-pointer"
      >
        <BsWhatsapp color="#5C4033" className="size-5 md:size-9" />

        <p className="text-center text-primary font-semibold text-[20px] ml-3 md:text-[36px]">
          {HomeContent.whatsAppNumber}
        </p>
      </div>
    </>
  );
}

export default AboutUs;
