import IroningServices from "../assets/ourServices/ironingServices.png";
import DryCleanServices from "../assets/ourServices/dryCleanServices.png";
import PerfumeConditioningServices from "../assets/ourServices/perfumeConditioning.png";
import GermKillServices from "../assets/ourServices/germ-kill.png";
import InnovativePackagingServices from "../assets/ourServices/packaging.png";
import DelicateFabricServices from "../assets/ourServices/delicateFibre.png";
import GoogleImg from "../assets/google.png";
import AppleImg from "../assets/apple.png";
export const HomeContent = {
  wlcMsg: "WELCOME TO PRESSWALA ENGINEER",
  subHeadAboutMsg: {
    wash: "Wash",
    ironing: " Ironing",
    dryClean: " DryClean",
  },
  aboutMsg:
    "We are a comprehensive laundry solution powered by advanced robotic machines and eco-friendly practices. Our services deliver exceptional quality in record time. Unique features such as perfume conditioning, germ-killing disinfectant ironing, and innovative packaging ensure your clothes look and feel as good as new.",
  whyChoose: {
    title: "Why Choose Us?",
    options: [
      { id: 1, label: "Robotic Machines for high quality Service" },
      { id: 2, label: "Eco-Friendly Drycleaning Solutions" },
      { id: 3, label: "100% Satisfaction Guaranteed" },
      { id: 4, label: "Lowest Cost Promise" },
    ],
  },
  ourServices: {
    title: "Our Services",
    services: [
      {
        id: 1,
        label: "Ironing\nServices",
        bgColor: "#FCADDF",
        img: IroningServices,
      },
      {
        id: 2,
        label: "Dry Cleaning",
        bgColor: "#1193FF",
        img: DryCleanServices,
      },
      {
        id: 3,
        label: "Perfume Conditioning",
        bgColor: "#F7E8CB",
        img: PerfumeConditioningServices,
      },
      {
        id: 4,
        label: "Germ Kill Disinfectant Ironing",
        bgColor: "#0AE3C4",
        img: GermKillServices,
      },
      {
        id: 5,
        label: "Innovative Packaging",
        bgColor: "#B4EA27",
        img: InnovativePackagingServices,
      },
      {
        id: 6,
        label: "Organic Fabric Washing",
        bgColor: "#C62E46",
        img: DelicateFabricServices,
      },
    ],
  },
  contactNumber: "+91-8800443220",
  pureNumber: "8800443220",
  whatsAppNumber: "+91-8882792728",
  pureWhatsAppNumber: "918882792728",
  googleImg: GoogleImg,
  appleImg: AppleImg,
  playStoreUrl:
    "https://play.google.com/store/apps/details?id=com.presswalaengineer",
  appleStoreUrl:
    "https://apps.apple.com/in/app/presswala-engineer/id6498629129",
};

export const FooterContent = {
  header: "Presswala Engineer",
  desc: " A PMEGP funded Made in India 🇮🇳 Organisation to provide cost effective and quality services to the consumer. We run our operations with latest technology and up to date equipments.",
  office: {
    header: "Postal Mail",
    desc: "Presswala Engineer, Sikanderpur Village, Sector 85, Sihi, Gurugram, Haryana 122004",
    mob: HomeContent.contactNumber,
    email: "CustomerCare@presswalaengineer.com",
  },
  OperatingHoursHeader: "Operating Hours",
  OperatingHours: [
    {
      id: 1,
      label: "Monday - Sunday | 08:00 - 20:30",
    },
  ],
};

export const TestimonialContent = {
  headers: "",
  allTestimonial: [
    {
      id: 1,
      testimonial:
        "Impressed by PressWala Engineer's organized, automated factory. Robots iron and fold clothes, eco-friendly with zero emissions. No plastic model, great for responsible citizens. Service and punctuality set a good example for young people.",
      by: "Aastha Gupta",
    },
    {
      id: 2,
      testimonial:
        "PressWala Engineer is not just an app, it's a feeling of pride to use something locally made in India. It's a business designed for customer convenience. The Indian market would indeed take pride in having something like PressWala Engineer.",
      by: "J.D Mehta",
    },
    {
      id: 3,
      testimonial:
        "Cost-convenient, perfume conditioning for Indian families is an innovative feature I found most attractive. The clothes are as good as new, and their key value is consistency. Now, even my kids wear ironed clothes.",
      by: "Vigyat Verma",
    },
    {
      id: 4,
      testimonial:
        "The model is satisfying, offering freedom in ironing services, unlike local vendors where you're part of a mafia. PressWala Engineer is cost-effective, promising, and top-quality.",
      by: "Shilpa Kapoor",
    },
  ],
};

export const ContactContent = {
  header: "CONTACT US !",
  contactUsFor: [
    {
      id: 1,
      label: "Visit To Factory Invitation",
    },
    {
      id: 2,
      label: "Run Ads On App (Enquiry)",
    },
    {
      id: 3,
      label: "Become A Franchise Partner",
    },
    {
      id: 4,
      label: "Become An Investor",
    },
    {
      id: 5,
      label: "Account Deletion ",
    },
  ],
};

export const aboutContent = {
  aboutMe:
    "PressWala Engineer is a boutique for Ironing and DryCleaning, where top-notch service is provided through robotic machinery, ensuring both efficiency and affordability. Our distinct offerings encompass Perfume Conditioning, Germ Kill Disinfectant Ironing, and inventive packaging, revitalizing your garments to their original freshness. We prioritize swift delivery without compromising quality, and our competent team guarantees meticulous care for your attire, delivering impeccable results every time.",
  AchievementsData: [
    {
      id: 1,
      value: "Multiple",
      title: "Ironing and DryCleaning Shop",
    },
    {
      id: 2,
      value: "Premium Laundry Solution",
      title: "Affordable Prices",
    },
    {
      id: 3,
      value: "Organic",
      title: "Perfume Conditioning",
    },
    {
      id: 4,
      value: "Eco-Friendly",
      title: "Innovative Packaging",
    },
  ],
};
